import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IImage } from '@utils/contentful/contentfulImage'
import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import {
  TDropdownTileSubtitleColor,
  TDropdownTileTitleColor,
  TDropdownTileTitleSize,
} from './TileSection.types'
import {
  useSelectedPrimaryMenu,
  useSelectedSecondaryMenu,
} from '@components/common/Navbar/NavbarDataProvider'
import { useTrackingBreadcrumbs } from '@utils/hooks/useTrackingBreadcrumbs'
import { useState } from 'react'
import classnames from 'classnames'

const tileTitleSizeMapper: {
  [key in TDropdownTileTitleSize]: string
} = {
  S: 'text-[14px]',
  M: 'text-[18px]',
  L: 'text-[22px]',
}

const tileTitleColorMapper: {
  [key in TDropdownTileTitleColor]: string
} = {
  white: 'text-white',
  purple: 'text-acai',
}

const tileSubtitleColorMapper: {
  [key in TDropdownTileSubtitleColor]: string
} = {
  white: 'text-white',
  grey: 'text-black-60',
}

export function NavbarTile({
  title,
  titleSize,
  titleColor,
  subtitle,
  subtitleColor,
  tileLink,
  backgroundColor,
  image,
  images = [],
  layout,
  siblingIsFeatureTile,
}: {
  title: string
  titleSize: string
  titleColor: string
  subtitle: string
  subtitleColor: string
  tileLink: string
  backgroundColor: string
  image: IImage
  images: IImage[]
  layout: 'regular' | 'feature' | 'compact'
  siblingIsFeatureTile: boolean
}) {
  const router = useRouter()
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const primaryImage = images?.[0] || image
  const secondaryImage = images?.[1] || null

  const { selectedPrimaryMenu } = useSelectedPrimaryMenu()
  const { selectedSecondaryMenu } = useSelectedSecondaryMenu()
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()

  const hasTitleOrSubtitle = subtitle || title
  const direction = layout === 'compact' ? 'horizontal' : 'vertical'

  return (
    <a
      href={tileLink}
      onClick={() => {
        if (tileLink) {
          trackNavigationClick({
            clickLocation: 'Dropdown Tile',
            clickItem: title,
          })

          const breadcrumbs = [
            {
              name: selectedPrimaryMenu.primaryMenuTitle,
              url: selectedPrimaryMenu.primaryMenuLink,
            },
          ]

          if (selectedSecondaryMenu?.title) {
            breadcrumbs.push({
              name: selectedSecondaryMenu?.title,
              url: selectedSecondaryMenu?.titleLinkUrl,
            })
          }

          breadcrumbs.push({
            name: title,
            url: tileLink,
          })

          saveBreadcrumbs(breadcrumbs)

          router.push(tileLink).catch(() => {})
        }
      }}
      className={`mr-[16px] last:mr-0 rounded-[12px] w-full group flex flex-col  ${
        layout === 'feature' ? 'basis-[150%]' : 'basis-full'
      } ${
        direction === 'horizontal'
          ? '!flex-row gap-[16px] items-center'
          : 'justify-between'
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      {image?.imageUrl && (
        <div
          className={`relative w-full rounded-[12px] overflow-hidden ${
            siblingIsFeatureTile ? 'h-full' : ''
          } ${
            hasTitleOrSubtitle ? 'aspect-4/3 max-h-[220px]' : 'aspect-square'
          } ${
            direction === 'horizontal' ? '!aspect-square basis-[40%]' : 'w-full'
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className={classnames({
              hidden: isHovered && secondaryImage,
            })}
          >
            <ContentfulNextJsImage
              src={primaryImage?.imageUrl || ''}
              alt={primaryImage?.imageTitle || ''}
              backgroundColour={backgroundColor}
              quality={75}
              fit="fill"
              fill={true}
              format="jpg"
              className={classnames({
                'transition-transform duration-300 md:group-hover:scale-105':
                  !secondaryImage,
              })}
              priority={true}
              sizes="(max-width: 480px) 90vw, (max-width: 768px) 40vw, (max-width: 1280px) 25vw, 15vw"
              blurPlaceholderOn={false}
            />
          </div>

          <div
            className={classnames({
              hidden: !isHovered || !secondaryImage,
            })}
          >
            <ContentfulNextJsImage
              src={secondaryImage?.imageUrl || ''}
              alt={secondaryImage?.imageTitle || ''}
              backgroundColour={backgroundColor}
              quality={75}
              fit="fill"
              fill={true}
              format="jpg"
              priority={true}
              sizes="(max-width: 480px) 90vw, (max-width: 768px) 40vw, (max-width: 1280px) 25vw, 15vw"
              blurPlaceholderOn={false}
            />
          </div>
        </div>
      )}
      {hasTitleOrSubtitle && (
        <div
          className={`flex flex-col font-mori text-acai ${
            direction === 'horizontal'
              ? 'min-w-[40%] max-w-[40%] items-start'
              : 'p-[24px] items-center '
          }`}
        >
          {subtitle && (
            <div
              className={`text-[11px] leading-[16px] min-h-[16px] ${
                direction === 'horizontal' ? 'text-left' : 'text-center'
              } ${
                tileSubtitleColorMapper[subtitleColor] ||
                tileSubtitleColorMapper.grey
              } ${isHovered ? 'text-pitaya' : ''}`}
            >
              {subtitle}
            </div>
          )}

          {title && (
            <h4
              className={`${
                tileTitleSizeMapper[titleSize] || tileTitleSizeMapper.L
              } ${
                tileTitleColorMapper[titleColor] || tileTitleColorMapper.purple
              } ${direction === 'horizontal' ? 'text-left' : 'text-center'} ${
                isHovered ? 'text-pitaya' : ''
              } font-bold leading-[24px]`}
            >
              {title}
            </h4>
          )}
        </div>
      )}
    </a>
  )
}
