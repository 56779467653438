import React from 'react'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IImage } from '@utils/contentful/contentfulImage'

export interface IFooterBanner {
  title: string
  leftIamge: IImage
  link: string
  linkLabel: string
  className?: string
}

export const FooterBanner: React.FC<IFooterBanner> = (props) => {
  const { title, leftIamge, link, linkLabel, className } = props

  return (
    <div
      className={`flex justify-center mx-8 sm:mx-0 p-4 rounded-[16px] items-center bg-unbleached-40 ${className}`}
    >
      <div className="relative flex items-center min-w-[150px] sm:min-w-[170px] min-h-[80px] justify-center order-1 lg:order-2">
        {leftIamge.imageUrl && (
          <ContentfulNextJsImage
            src={leftIamge.imageUrl}
            alt={leftIamge.imageTitle}
            fill={true}
            objectFit="contain"
          />
        )}
      </div>
      <div className="order-2 mx-6 lg:ml-0 lg:text-right lg:order-1">
        <p className={`my-2 text-boulder text-xl lg:text-lg font-semibold`}>
          {title}
          <a
            className="ml-2 text-xl font-semibold cursor-pointer lg:text-lg text-pitaya-100"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkLabel}
          </a>
        </p>
      </div>
    </div>
  )
}
