export async function getOrders(page: number, limit = 10) {
  const result = await fetch(
    `/api/bigcommerce/orders/?limit=${limit}&page=${page}`
  )

  try {
    const res = await result.json()
    return res
  } catch {
    return { orders: [] }
  }
}
